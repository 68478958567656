import axios from 'axios';

const instance = axios.create({
    baseURL: 'https://hotel.liforte.com/fnb', // Thay thế bằng URL của API của bạn
    timeout: 10000, // Thời gian chờ tối đa (milliseconds)
    headers: {
        'Content-Type': 'application/json', // Loại nội dung của request là JSON
        // Thêm các header khác nếu cần
    },
});

// Middleware để xử lý request trước khi gửi
instance.interceptors.request.use(
    (config) => {
        // Thực hiện các xử lý trước khi gửi request (nếu cần)
        return config;
    },
    (error) => {
        // Xử lý lỗi khi gửi request
        return Promise.reject(error);
    }
);

// Middleware để xử lý response sau khi nhận được
instance.interceptors.response.use(
    (response) => {
        // Thực hiện các xử lý trên response (nếu cần)
        return response.data;
    },
    (error) => {
        // Xử lý lỗi khi nhận được response
        return Promise.reject(error);
    }
);

export default instance;