import React from 'react'

const TrangChu = () => {
  return (
    <div className='over' id='trangchu'>
      <div className='over over--background'>
        <div className='container'>
          <div className='header__introduce'>
            <div className='title'> Hệ thống phần mềm quản lý khách sạn toàn diện, thông minh. </div>
            <div className='description' style={{ margin: '0 0 50px 0' }}> Áp dụng đa dạng cho mọi loại hình khách sạn, nhà nghỉ, khu nghỉ dưỡng, homestay... </div>
            {/* <div className='button'> GIỚI THIỆU </div> */}
          </div>
          {/* <img src={require('../../assets/img/imgHotelHeader.png')} alt="hotel-info" className='header__introduce--img' /> */}
          <video autoPlay loop muted className='header__introduce--img' >
            <source src={require('../../assets/img/VideoDemo.mp4')} type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  )
}

export default TrangChu